export default $axios => ({
  index () {
    return $axios.get('workspace_memberships')
  },

  store (data) {
    return $axios.post('workspace_memberships', data)
  },

  show (workspaceMembershipId) {
    return $axios.get(`workspace_memberships/${workspaceMembershipId}`)
  },

  update (workspaceMembershipId, data) {
    return $axios.put(`workspace_memberships/${workspaceMembershipId}`, data)
  },

  destroy (workspaceMembershipId) {
    return $axios.delete(`workspace_memberships/${workspaceMembershipId}`)
  }
})
