
import NavigationItems from '@/components/next/layout/navigation/items/NavigationItems'
import NavigationItem from '@/components/next/layout/navigation/items/NavigationItem'
import AnnoucementNavigationItem from '@/components/next/layout/announcements/AnnoucementNavigationItem.vue'
import HandlesDismissals from '@/components/next/utilities/HandlesDismissals'

export default {
  name: 'NavigationBottomItems',
  components: {
    NavigationItem,
    NavigationItems,
    AnnoucementNavigationItem
  },
  mixins: [HandlesDismissals],
  computed: {
    dismissableBenefitsKeys () {
      return []
    },

    undismissedBenefitKeys () {
      const dismissedKeys = this.$auth.user?.user?.dismissed_keys || []
      return this.dismissableBenefitsKeys.filter(key => !dismissedKeys.includes(key))
    },

    undismissedBenefitsBadgeText () {
      return this.undismissedBenefitKeys.length > 0 ? String(this.undismissedBenefitKeys.length) : null
    },

    helpCenterUrl () {
      return 'https://hilfe.campflow.de'
    }
  },

  methods: {
    async dismissAllUndismissed () {
      const promises = this.undismissedBenefitKeys.map((key) => {
        return this.dismiss(key)
      })

      await Promise.all(promises)
      await this.$auth.fetchUser()
    }
  }
}
