
import CogwheelMenu from '@/components/next/layout/cogwheel/CogwheelMenu'
import NavigationTopItems from '@/components/next/layout/navigation/NavigationTopItems'
import NavigationBottomItems from '@/components/next/layout/navigation/NavigationBottomItems'
import LegalLinks from '@/components/next/layout/legal/LegalLinks'
import WorkspaceSwitcher from '@/components/next/layout/workspace_switcher/WorkspaceSwitcher.vue'
import NavigationBranding from '@/components/next/layout/navigation/NavigationBranding.vue'

export default {
  name: 'LayoutSidebar',
  components: {
    NavigationBranding,
    WorkspaceSwitcher,
    CogwheelMenu,
    NavigationTopItems,
    NavigationBottomItems,
    LegalLinks
  },

  data () {
    return {
      workspace: null
    }
  }
}
