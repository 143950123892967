export default class LiamAuthScheme {
  constructor ($auth, options) {
    this.$auth = $auth
    this.options = options

    $auth.onRedirect((to, from) => {
      return to + '?redirectUrl=' + encodeURIComponent(window.location.href)
    })
  }

  get name () {
    return this.options.name
  }

  async fetchUser (endpoint) {
    const axios = this.$auth.ctx.$axios
    const response = await axios.get('/me')
    this.$auth.setUser(response.data.data)
  }
}
