
export default {
  name: 'NavigationBranding',
  computed: {
    brandingProfile () {
      return this.$auth.user?.workspace?.branding_profile
    },

    hasBranding () {
      return this.brandingProfile !== null
    },

    sidebarLabel () {
      return this.hasBranding ? this.brandingProfile.sidebar_label : null
    },

    sidebarName () {
      return this.hasBranding ? this.brandingProfile.sidebar_name : null
    },

    sidebarLogoUrl () {
      return this.hasBranding ? this.brandingProfile.sidebar_logo_url : null
    },

    hasLogo () {
      return this.sidebarLogoUrl !== null
    },

    sidebarInfoUrl () {
      return this.hasBranding ? this.brandingProfile.sidebar_info_url : null
    }
  }
}
