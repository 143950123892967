export const state = () => ({
  workspaceSlug: null
})

export const mutations = {
  setWorkspaceSlug (state, workspaceSlug) {
    state.workspaceSlug = workspaceSlug
  }
}

export const actions = {
  hydrateWorkspaceSlug ({ commit }) {
    const workspaceSlug = localStorage.getItem('cf_workspace_slug')
    if (workspaceSlug) {
      commit('setWorkspaceSlug', workspaceSlug)
    }
  },
  updateWorkspaceSlug ({ commit }, workspaceSlug) {
    commit('setWorkspaceSlug', workspaceSlug)
    localStorage.setItem('cf_workspace_slug', workspaceSlug)
  }
}

export const getters = {
  currentWorkspaceSlug (state) {
    return state.workspaceSlug
  }
}
