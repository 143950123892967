
import Teleport from 'vue2-teleport'
import CogwheelMenuItem from '@/components/next/layout/cogwheel/CogwheelMenuItem'

export default {
  name: 'CogwheelMenu',
  components: { Teleport, CogwheelMenuItem },
  data () {
    return {
      dropdown_open: false,
      show_developer: false
    }
  },
  computed: {
    nameAndEmail () {
      const items = [
        this.$auth.user?.user?.name,
        this.$auth.user?.user.email
      ]

      return items.join(' · ')
    },
    impersonationPin () {
      return this.$auth.user?.user?.support_pin || '-'
    },
    authBaseurl () {
      return process.env.AUTH_BASE_URL
    }
  },
  async mounted () {
    this.show_developer = await this.$flags('public_api', false)
  },
  methods: {
    toggle () {
      this.dropdown_open = !this.dropdown_open
    },
    close () {
      this.dropdown_open = false
    },
    async logout () {
      await this.$api.workspaceSettings.logoutUser()
      window.location.replace(`${this.authBaseurl}/login`)
    }
  }
}
