
import fallbackLogo from 'assets/logos/campflow_circle.png'
import Teleport from 'vue2-teleport'

export default {
  name: 'WorkspaceSwitcher',
  components: { Teleport },
  data () {
    return {
      dropdown_open: false
    }
  },
  computed: {
    addWorkspaceUrl () {
      return process.env.AUTH_BASE_URL + '/workspaces'
    },
    firstBillingResourceUrl () {
      if (this.$auth.user.user.pools.length > 0) {
        return this.$auth.user.user.pools[0].url
      }

      if (this.$auth.user.user.debtors.length > 0) {
        return this.$auth.user.user.debtors[0].url
      }

      return null
    },
    currentWorkspace () {
      return this.$auth.user.workspace
    },
    currentWorkspaceId () {
      return this.currentWorkspace.id
    },
    currentWorkspaceLogoUrl () {
      return this.getLogoUrl(this.currentWorkspace)
    },
    currentWorkspaceName () {
      return this.currentWorkspace.name
    },
    availableWorkspaces () {
      return this.$auth.user.user.workspaces
    }
  },
  methods: {
    toggle () {
      this.dropdown_open = !this.dropdown_open
    },
    getLogoUrl (workspace) {
      return workspace.logo?.url || fallbackLogo
    },
    isSelected (workspace) {
      return workspace.id === this.currentWorkspaceId
    }
  }
}
