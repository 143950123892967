
export default {
  name: 'OfferItem',
  props: {
    offer: {
      type: Object,
      required: true
    },
    selected: Boolean
  },
  computed: {
    title () {
      return this.offer.flavor_name
    },

    tagline () {
      return this.$t('workspace.billing.subscription.' + this.offer.flavor + '.tagline')
    },

    selectable () {
      return this.offer.enrolment_flow === 'unavailable'
    },

    isPro () {
      return this.offer.flavor === 'pro'
    },

    isFree () {
      return this.offer.amount === 0
    },

    isAnnual () {
      return this.offer.billing_frequency === 'annual'
    },

    annualAmount () {
      return this.offer.annual_amount
    },

    monthlyAmount () {
      return this.offer.monthly_amount
    },

    topPriceLine () {
      if (this.isFree) {
        return this.$t('workspace.billing.subscription.price_free_top')
      }

      return this.isAnnual
        ? this.formattedAnnualAmount
        : this.formattedMonthlyAmount
    },

    bottomPriceLine () {
      if (this.isFree) {
        return this.$t('workspace.billing.subscription.price_free_bottom')
      }

      return this.isAnnual
        ? this.formattedMonthlyAmount
        : this.formattedAnnualAmount
    },

    anchorAmount () {
      if (this.annualAmount === this.offer.anchor_amount) {
        return null
      }

      return this.offer.anchor_amount
    },

    formattedAnchorAmount () {
      if (this.anchorAmount === null) {
        return null
      }

      return this.formatCurrencyWithoutDecimals(this.anchorAmount)
    },

    formattedMonthlyAmount () {
      const formattedAmount = this.formatCurrencyWithoutDecimals(this.monthlyAmount)
      return this.$t('workspace.billing.subscription.price_per_month', { amount: formattedAmount })
    },

    formattedAnnualAmount () {
      const formattedAmount = this.formatCurrencyWithoutDecimals(this.annualAmount)
      return this.$t('workspace.billing.subscription.price_per_year', { amount: formattedAmount })
    }
  },
  methods: {
    formatCurrencyWithoutDecimals (amount) {
      const decimalAmount = amount / 100
      const currencySuffix = ' €'

      if (amount % 100 === 0) {
        return decimalAmount + currencySuffix
      }

      return decimalAmount.toFixed(2) + currencySuffix
    },
    select () {
      if (this.selectable) {
        return
      }

      this.$emit('select')
    }
  }
}
