
import CfToggle from '~/components/next/ui/form/CfToggle.vue'
import CfLabel from '~/components/next/ui/form/CfLabel.vue'

export default {
  name: 'BillingFrequencyChooser',
  components: {
    CfToggle,
    CfLabel
  },
  props: {
    value: {
      type: String,
      required: true
    },
    locked: Boolean
  },
  computed: {
    annual: {
      get () {
        return this.value === 'annual'
      },
      set (annual) {
        if (this.locked) {
          return
        }

        this.$emit('input', annual ? 'annual' : 'monthly')
      }
    },
    text () {
      return !this.locked || this.annual
        ? this.$t('workspace.billing.subscription.annual_toggle')
        : this.$t('workspace.billing.subscription.monthly_toggle')
    }
  },
  methods: {
    toggleYearly () {
      this.annual = !this.annual
    }
  }
}
