export default $axios => ({
  store (divisionId, data) {
    return $axios.post(`divisions/${divisionId}/invite`, data)
  },

  destroy (divisionId) {
    return $axios.delete(`divisions/${divisionId}/invite`)
  },

  show (connectInviteKey) {
    return $axios.get(`connect_invites/${connectInviteKey}`)
  },

  showMappingInfo (connectInviteKey) {
    return $axios.get(`connect_invites/${connectInviteKey}/mapping`)
  },

  accept (connectInviteKey, data) {
    return $axios.put(`connect_invites/${connectInviteKey}`, data)
  }
})
