
import CfModal from '@/components/next/ui/modal/CfModal.vue'
import CfButton from '@/components/next/ui/button/CfButton.vue'

export default {
  name: 'SubscribedModal',
  components: { CfButton, CfModal },
  computed: {
    name () {
      return this.$auth.user?.user?.name
    }
  },
  methods: {
    async close () {
      await this.$auth.fetchUser()
      this.$emit('close')
    }
  }
}
