
import CfAsideIcon from '@/components/next/ui/icon/CfAsideIcon.vue'
import CfModalHeading from '@/components/next/ui/modal/CfModalHeading.vue'
import CfParagraph from '@/components/next/ui/text/CfParagraph.vue'

export default {
  name: 'NoPermissionError',
  components: {
    CfAsideIcon,
    CfModalHeading,
    CfParagraph
  }
}
