
import CfExternalLinkButton from '@/components/next/ui/button/CfExternalLinkButton.vue'

export default {
  name: 'EphemeralWorkspaceError',
  components: {
    CfExternalLinkButton
  },
  computed: {
    registrationUrl () {
      return process.env.AUTH_BASE_URL + '/register'
    }
  }
}
