
import CfModal from '@/components/next/ui/modal/CfModal.vue'
import CfModalHeading from '@/components/next/ui/modal/CfModalHeading.vue'
import CfExplanation from '@/components/next/ui/text/CfExplanation.vue'
import CfButton from '@/components/next/ui/button/CfButton.vue'
import CfParagraph from '@/components/next/ui/text/CfParagraph.vue'

export default {
  name: 'LicenseRequestCreatedModal',
  components: { CfParagraph, CfButton, CfExplanation, CfModalHeading, CfModal },
  props: {
    licenseRequest: {
      type: Object,
      required: true
    }
  },
  computed: {
    pool () {
      return this.licenseRequest.pool
    },
    offer () {
      return this.licenseRequest.offer
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
