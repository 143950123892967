export default ({ $axios, app }) => {
  app.store.dispatch('workspaceSlug/hydrateWorkspaceSlug')

  $axios.interceptors.request.use((config) => {
    const currentWorkspaceSlug = app.store.getters['workspaceSlug/currentWorkspaceSlug']
    if (currentWorkspaceSlug) {
      config.headers['x-campflow-workspace'] = currentWorkspaceSlug
    }
    return config
  })
}
