

export default {
  name: 'OfferModalMasthead',
  props: {
    featureUnavailable: Boolean
  },
  computed: {
    text () {
      return this.$t('workspace.billing.subscription.masthead.' + this.key)
    },

    key () {
      if (this.$auth.user?.workspace.demo.ephemeral) {
        return 'ephemeral'
      }

      if (this.onBenefitsPage) {
        return 'paid_benefits'
      }

      if (this.$auth.user.workspace.trial.ongoing) {
        return 'trial_ongoing'
      }

      if (!this.$auth.user.workspace.product.paying) {
        return 'trial_ended'
      }

      if (this.$auth.user.workspace.product.paying && this.featureUnavailable) {
        return 'upgrade_required'
      }

      if (this.$auth.user.workspace.product.flavor !== 'pro') {
        return 'upgrade_possible'
      }

      return 'generic'
    },

    onBenefitsPage () {
      return this.$route.path.startsWith('/benefits')
    }
  }
}
