
import CfButton from '@/components/next/ui/button/CfButton.vue'

export default {
  name: 'TrialExtensionOffer',
  components: { CfButton },
  props: {
    loading: Boolean
  },

  data () {
    return {
      extendingTrial: false
    }
  },

  computed: {
    trialExtensible () {
      return this.$auth.user?.workspace.trial.extensible
    }
  },

  methods: {
    async extendTrial () {
      try {
        this.extendingTrial = true
        await this.$api.trialExtensions.store()
        this.$emit('close')
      } catch (err) {
        this.$errors.handle(err)
        this.extendingTrial = false
      }
    }
  }
}
