export default $axios => ({
  fetchOffers () {
    return $axios.get('workspace_billing/offers')
  },

  fetchPool (enrolmentCode) {
    return $axios.get(`workspace_billing/pools/${enrolmentCode}`)
  },

  enrolInOffer (offer) {
    return $axios.post(`workspace_billing/offers/${offer}`)
  },

  cancelLicense () {
    return $axios.post('workspace_billing/cancellations')
  },

  updateUpgrades (data) {
    return $axios.put('workspace_billing/upgrades', data)
  },

  fetchInvoices () {
    return $axios.get('workspace_billing/invoices')
  },

  updatePaymentMethod (data) {
    return $axios.post('workspace_billing/payment_method', data)
  }
})
