
import CfModal from '@/components/next/ui/modal/CfModal.vue'
import BillingFrequencyChooser from '@/components/next/workspace/billing/offers/offers/BillingFrequencyChooser.vue'
import CfExternalLink from '@/components/next/ui/text/CfExternalLink.vue'
import CfModalHeading from '@/components/next/ui/modal/CfModalHeading.vue'
import OfferItem from '@/components/next/workspace/billing/offers/offers/OfferItem.vue'
import OfferGhost from '@/components/next/workspace/billing/offers/offers/OfferGhost.vue'
import CfButton from '@/components/next/ui/button/CfButton.vue'
import LicenseRequestCreatedModal
  from '@/components/next/workspace/billing/offers/licenseRequests/LicenseRequestCreatedModal.vue'
import SubscribedModal from '@/components/next/workspace/billing/success/SubscribedModal.vue'
import CfParagraph from '@/components/next/ui/text/CfParagraph.vue'
import OfferModalMasthead from '@/components/next/workspace/billing/offers/masthead/OfferModalMasthead.vue'
import EphemeralWorkspaceError from '@/components/next/workspace/billing/offers/errors/EphemeralWorkspaceError.vue'
import NoPermissionError from '@/components/next/workspace/billing/offers/errors/NoPermissionError.vue'
import TrialExtensionOffer from '@/components/next/workspace/billing/offers/trial/TrialExtensionOffer.vue'

export default {
  name: 'OfferModal',
  components: {
    NoPermissionError,
    EphemeralWorkspaceError,
    OfferModalMasthead,
    CfParagraph,
    CfButton,
    OfferGhost,
    OfferItem,
    CfModalHeading,
    CfExternalLink,
    BillingFrequencyChooser,
    CfModal,
    TrialExtensionOffer
  },
  props: {
    poolEnrolmentCode: {
      type: String,
      default: null
    },
    autoSubmittedOfferId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      fetching: false,
      enroling: false,

      overriddenPool: null,
      offers: [],
      manuallySelectedFrequency: 'annual',

      selectedOfferId: null
    }
  },
  computed: {
    visiblePool () {
      return this.overriddenPool ?? this.$auth.user?.workspace.pool
    },

    visibleOffers () {
      return this.offers.filter(offer => offer.billing_frequency === this.selectedFrequency)
    },

    selectedOffer: {
      get () {
        return this.offers.find(offer => offer.id === this.selectedOfferId)
      },
      set (offer) {
        this.selectedOfferId = offer?.id || null
      }
    },

    ghostCount () {
      if (this.fetching) {
        return (this.poolEnrolmentCode || this.visiblePool) ? 2 : 4
      }

      return 0
    },

    frequenciesSet () {
      return new Set(this.offers.map(offer => offer.billing_frequency))
    },

    lockedFrequency () {
      if (this.offers.length === 0) {
        return 'annual'
      }

      if (this.frequenciesSet.size === 1) {
        return this.offers[0].billing_frequency
      }

      return null
    },

    selectedFrequency: {
      get () {
        return this.lockedFrequency || this.manuallySelectedFrequency
      },
      set (billingFrequency) {
        this.manuallySelectedFrequency = billingFrequency
      }
    },

    enrolmentFlow () {
      if (!this.fetching && this.selectedOffer) {
        return this.selectedOffer.enrolment_flow
      }

      return 'unavailable'
    },

    heading () {
      if (this.visiblePool) {
        return this.$t('workspace.billing.enrolment.heading', { pool: this.visiblePool.partner_name })
      } else {
        return this.$t('workspace.billing.subscription.heading')
      }
    },

    enrolmentButtonText () {
      if (this.enrolmentFlow === 'apply_offer' && this.selectedOffer.amount === 0) {
        return this.$t('workspace.billing.subscription.button_apply_free_offer')
      }

      return this.$t('workspace.billing.subscription.button_' + this.enrolmentFlow)
    },

    enrolmentButtonDisabled () {
      return this.enrolmentFlow === 'unavailable' || this.enrolmentFlow === 'no_op'
    },

    isEphemeral () {
      return this.$auth.user?.workspace.demo.ephemeral
    },

    lacksPermission () {
      return this.$access.doesntHavePermission('admin')
    },

    columns () {
      return this.visibleOffers.length + this.ghostCount >= 2 ? 2 : 1
    },

    columnsClass () {
      return this.columns >= 2 ? 'grid-cols-2' : 'grid-cols-1'
    },

    modalSize () {
      if (this.isEphemeral) {
        return 'sm'
      }

      if (this.columns === 2) {
        return 'md'
      } else {
        return 'xs'
      }
    }
  },
  watch: {
    visibleOffers: {
      handler () {
        this.preselectFlavor(this.selectedOffer?.flavor || 'pro')
      },
      immediate: true
    }
  },
  async mounted () {
    if (this.lacksPermission) {
      return
    }

    await this.fetchOffers()

    if (this.autoSubmittedOfferId) {
      this.selectedOfferId = this.autoSubmittedOfferId
      await this.enrolInOffer()
    }
  },
  methods: {
    async fetchOffers () {
      this.fetching = true
      try {
        if (this.poolEnrolmentCode) {
          const response = await this.$api.billing.fetchPool(this.poolEnrolmentCode)
          this.overriddenPool = response.data.data
          this.offers = response.data.data.offers
        } else {
          const response = await this.$api.billing.fetchOffers()
          this.offers = response.data.data
        }
      } catch (error) {
        this.$errors.handle(error)
      } finally {
        this.fetching = false
      }
    },

    async enrolInOffer () {
      if (this.enrolmentFlow === 'no_op') {
        await this.closeAndRefresh()
        return
      }

      this.enroling = true

      try {
        const response = await this.$api.billing.enrolInOffer(this.selectedOfferId)
        this.enroling = false

        if (response.status === 204) {
          // No action required, offer was applied
          await this.$modal(SubscribedModal)
          await this.closeAndRefresh()
        } else if (response.data.data.redirect_url) {
          // Redirect required
          location.assign(response.data.data.redirect_url)
        } else {
          await this.$modal(LicenseRequestCreatedModal, {
            licenseRequest: response.data.data
          })
          await this.closeAndRefresh()
        }
      } catch (err) {
        this.enroling = false
        this.$errors.handle(err)
      }
    },

    close () {
      return this.$emit('close')
    },

    async closeAndRefresh () {
      await this.$auth.fetchUser()
      this.close()
    },

    preselectFlavor (preferredFlavor) {
      const offerWithPreferredFlavor = this.visibleOffers
        .find(offer => offer.flavor === preferredFlavor)

      if (offerWithPreferredFlavor) {
        this.selectOffer(offerWithPreferredFlavor)
        return
      }

      // If there's only one offer, preselect it
      if (this.visibleOffers.length === 1) {
        this.selectOffer(this.visibleOffers[0])
        return
      }

      // If all offers are free, don't preselect any
      if (this.visibleOffers.every(offer => offer.amount === 0)) {
        this.selectOffer(null)
        return
      }

      // If all else fails, preselect the offer with the highest amount
      const offersSortedByAmount = this.visibleOffers
        .sort((a, b) => b.amount - a.amount)

      this.selectOffer(offersSortedByAmount[0] || null)
    },

    isOfferSelected (offer) {
      return this.selectedOfferId === offer?.id || null
    },

    selectOffer (offer) {
      this.selectedOffer = offer
    }
  }
}
